import { useEffect, useState } from "react";
import LiveChat from "react-livechat";
import Footer from "../../components/Footer/Footer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";

function CSKH() {
	const [profile, setProfile] = useState(null);
	const [isShow, setShow] = useState(false);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);


	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		setIsLoading(true);
		const timer = setTimeout(() => {
	
		setIsLoading(false);
		}, 2000);
		return () => clearTimeout(timer);
		
	}, []);
useEffect(()=>{
	axios
	.get(`https://server.mgm8386.com/auth/getUser`, {})
	.then((res) => {
		setProfile(res.data.data);
	})
	.catch((err) => localStorage.removeItem("user"));
},[])
	return (
		<>
			{isLoading ? (
				<div className="loading">
					<div className="loader"></div>
				</div>
			) : null}
			<div className="main">
				<Header profile={profile} />
				<h1 className="title-h1">Chăm Sóc Khách Hàng</h1>
				<div style={{position:"relative", height: "70vh"}}>
					<iframe src="https://chatlink.mstatik.com/widget/standalone.html?eid=a132347c9a405f745f87a8aea9e208e7&agentid=99cf382a4a905ffe52673639d1fc1cc4&language=vi" frameborder="0" width="100%" height="100%"></iframe>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default CSKH;
